import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Segment, Form, Button, Header, Grid, Confirm } from 'semantic-ui-react';
import Spinner from '../../common/Spinner';
import PropTypes from 'prop-types';
import Papa from 'papaparse';
import { uploadReplacement } from '../../../actions/readerActions';
import { getPublication } from '../../../actions/publicationActions';
import { isUserLoggedIn } from '../../common/isUserLoggedIn';

class UploadReplacement extends Component {
  state = {
    csvfile: undefined,
    error: undefined,
    publicationID: this.props.match.params.id,
    publication: null,
    uploading: false,
    open: false
  }

  show = () => this.setState({ open: true })
  handleConfirm = () => {
    this.setState({ open: false })
    this.importCSV();
  }
  handleCancel = () => this.setState({ result: 'cancelled', open: false })

  componentDidMount() {
    this.props.getPublication(this.props.match.params.id);
  }


  componentWillReceiveProps(nextProps) {
    isUserLoggedIn();
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.publication.publication) {
      this.setState({

        publication: nextProps.publication.publication
      });
    }
  }

  handleChange = event => {
    var a = event.target.files[0].name.split(".");
    if (a.length === 1 || (a[0] === "" && a.length === 2)) {
      this.setState({ error: "Invalid file type" });
    } else {
      if (a[1] !== "csv" && a[1] !== "CSV") {
        this.setState({ error: "Invalid file type" });
      } else {
        this.setState({
          csvfile: event.target.files[0],
          error: undefined
        })
      }
    };
  }

  importCSV = () => {
    const { csvfile } = this.state;
    this.setState({ uploading: true })
    if (this.state.error === undefined) {
      Papa.parse(csvfile, {
        complete: this.updateData,
        header: true
      });
    };
  }

  updateData = (result) => {
    var data = result.data;
    const uploaddata = {
      csvdata: data,
      publicationID: this.state.publicationID,
    }
    this.props.uploadReplacement(uploaddata, this.props.history)
  }

  handleError = err => {
    console.log("Error" + err);
  }

  render() {
    let displayFields = '';
    const error = this.state.error
    const { open } = this.state
    this.state.uploading ? displayFields = (
      <Segment>
        <Header as="h2">Please wait, data is uploading and processing</Header>
        <Spinner />
      </Segment>
    ) : displayFields = (
      <Segment>
        <Header as="h2" textAlign='center'>Upload Replacement Data for {this.state.publication !== null ? this.state.publication.publicationName : null}</Header>
        <p>Please download the CSV template <Link to="/assets/templates/magstanduploadtemplate.csv" target="_blank">by clicking here and saving</Link></p>
        <Segment>
          <Header as="h3">CSV Field Instructions</Header>
          <Grid columns="2">
            <Grid.Row>
              <Grid.Column>
                BuyerId
              </Grid.Column>
              <Grid.Column>
                Leave blank unless re-uploading data previously downloaded from your Magstand publications
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                UniqueReference
              </Grid.Column>
              <Grid.Column>
                Your unique reference for the reader
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                PubOffers* / PartnerOffers*
              </Grid.Column>
              <Grid.Column>
                FALSE or TRUE for Opt In or Out. Leave blank for TRUE (Opt Out)
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                SubStart / SubExpiry / SubRenew
              </Grid.Column>
              <Grid.Column>
                DD/MM/YYYY format. If left blank, will default to 01/01/1970 / 01/01/2100
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                SubType
              </Grid.Column>
              <Grid.Column>
                print, digital or bundle (Print and Digital)
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                SubPaid
              </Grid.Column>
              <Grid.Column>
                In decimal (i.e. 10.50 for £10.50, not 1050)
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                SubHistory
              </Grid.Column>
              <Grid.Column>
                Leave blank unless re-uploading data previously downloaded from your Magstand publications
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                DataSource1 / 2
              </Grid.Column>
              <Grid.Column>
                Where the reader was sourced from
              </Grid.Column>
            </Grid.Row>

          </Grid>
        </Segment>
        <p>Upload your CSV data</p>
        <Form.Input type="file" name="file" onChange={this.handleChange} />
        {error && <div className="ui pointing red basic label">{error}</div>}
        <br />
        <br />
        <Button onClick={this.show}>Upload</Button>
        <Confirm
          open={open}
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
          header='Confirm Replacement of your entire Reader Dataset?'
          content='All of the existing Reader data in the system will be deleted and replaced by clicking OK'
        />
      </Segment>
    )
    return (
      <React.Fragment>
        {displayFields}
      </React.Fragment>
    );
  }
}

UploadReplacement.propTypes = {
  uploadReplacement: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  // auth comes from the rootReducer
  auth: state.auth,
  errors: state.errors,
  reader: state.reader,
  publication: state.publication
});

export default connect(mapStateToProps, { uploadReplacement, getPublication })(withRouter(UploadReplacement));
