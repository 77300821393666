import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Form, Grid, Segment, Button } from "semantic-ui-react";
import { getPublications } from "../../../actions/publicationActions";
import { getAllData } from "../../../actions/readerActions";
import DataLayout from "./DataLayout";

class ChoosePub extends Component {
  state = {
    pubId: null,
    publicationOptions: [],
    publications: [],
  };
  componentDidMount() {
    if (this.props.auth.user.userType === "pubadmin") {
      this.props.getPublications(this.props.auth.user.parentPublisher);
    } else {
      this.props.getPublications(this.props.auth.user.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.publication.publications) {
      if (nextProps.publication.publications.length > 0) {
        if (nextProps.publication.publications !== this.state.publications) {
          let publicationOptions = [];
          nextProps.publication.publications.map((publication) => {
            publicationOptions.push({
              key: publication._id,
              text: publication.publicationName,
              value: publication._id,
            });
          });
          this.setState({
            publicationOptions: publicationOptions,
            publications: nextProps.publication.publications,
            pubId: publicationOptions[0].value,
          });
        }
      }
    }
  }

  handleChange = (e, { value }) => {
    this.setState({ pubId: value });
  };

  onAllDataHandler = (e) => {
    e.preventDefault();
    this.props.getAllData(this.state.pubId);
  };

  render() {
    const { publications } = this.props.publication;
    let publicationItems;

    if (publications !== null) {
      if (publications.length === 0) {
        publicationItems = (
          <Segment>
            <h4>No Publications found for this User</h4>
            <p>
              To add one, please go to the Publications menu option and click
              the Blue circle to the right
            </p>
          </Segment>
        );
      } else {
        if (publications.length > 0) {
          if (this.state.publicationOptions.length > 0) {
            publicationItems = (
              <Segment>
                <Grid>
                  <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Form.Select
                      options={this.state.publicationOptions}
                      onChange={this.handleChange}
                      placeholder="Publication"
                      fluid
                      size="large"
                      selection
                      name="pubId"
                      value={this.state.pubId}
                    />
                  </Grid.Column>
                  <Grid.Column
                    textAlign="right"
                    mobile={16}
                    tablet={8}
                    computer={4}
                  >
                    <Button onClick={this.onAllDataHandler} fluid color="blue">
                      View All Reader Data
                    </Button>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="right"
                    mobile={16}
                    tablet={8}
                    computer={4}
                  >
                    <Link to={`/uploadadditional/${this.state.pubId}`}>
                      <Button fluid color="blue">
                        Upload Additional Data
                      </Button>
                    </Link>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Link to={`/uploadreplacement/${this.state.pubId}`}>
                      <Button color="blue" fluid>
                        Upload Replacement Data
                      </Button>
                    </Link>
                  </Grid.Column>
                </Grid>
              </Segment>
            );
          }
        }
      }
    }

    return (
      <React.Fragment>
        {publicationItems}
        <DataLayout pubid={this.state.pubId} />
      </React.Fragment>
    );
  }
}

ChoosePub.propTypes = {
  getPublications: PropTypes.func.isRequired,
  publication: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  publication: state.publication,
  auth: state.auth,
});

export default connect(mapStateToProps, { getPublications, getAllData })(
  ChoosePub
);
